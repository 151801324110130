<template>
  <div class="popup_wrap" style="width:900px;"><!-- popup_wrap :  style="width:900px; height:600px;" 참고용 / 개발시 삭제 해주 세요. . -->
    <button
      class="layer_close"
      @click="$emit('close')"
    >close</button>
    <div class="popup_cont" ><!-- popup_cont -->

    <h1 class="page_title">{{ $t('msg.ONEX070G110.001') }}</h1><!-- O B/L 재발행 -->
    <form id="frm_reissue">
      <div class="content_box"><!-- content_box -->
        <h2 class="content_title">{{ $t('msg.ONEX070G110.002') }}</h2><!-- 스케줄 -->
        <table class="tbl_row">
          <colgroup>
            <col width="150px">
            <col width="*">
            <col width="150px">
            <col width="*">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.ONEX070G110.003') }}</th><!-- Booking No. -->
              <td>{{ scheduleInfo.bkgNo }}</td>
              <th>{{ $t('msg.ONEX070G110.004') }}</th><!-- B/L No. -->
              <td>{{ scheduleInfo.blNo }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONEX070G110.005') }}</th><!-- 출발 -->
              <td>{{ scheduleInfo.polPlcNm }}</td>
              <th>{{ $t('msg.ONEX070G110.006') }}</th><!-- 도착 -->
              <td>{{ scheduleInfo.podPlcNm }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONEX070G110.007') }}</th><!-- 선명 -->
              <td>{{ scheduleInfo.vslNm }}</td>
              <th>{{ $t('msg.ONEX070G110.008') }}</th><!-- 항차 -->
              <td>{{ scheduleInfo.voyNo }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONEX070G110.009') }}</th><!-- Shipper -->
              <td colspan="3">{{ scheduleInfo.actShprCstEnm }}</td>
            </tr>
          </tbody>
        </table>

        <h2 class="content_title">{{ $t('msg.ONEX070G110.010') }}</h2><!-- 요청자 연락처 -->
        <table class="tbl_col">
          <colgroup>
            <col width="30%">
            <col width="26%">
            <col width="18%">
            <col width="26%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.ONEX070G110.011') }}</th><!-- 회사명 -->
              <th>{{ $t('msg.ONEX070G110.012') }}</th><!-- 담당자 명 -->
              <th>{{ $t('msg.ONEX070G110.013') }}</th><!-- 연락처 -->
              <th>{{ $t('msg.ONEX070G110.014') }}</th><!-- email -->
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ scheduleInfo.shprCstNm }}</td>
              <td>
                <select
                  v-model="params.selPic"
                  class="inp_space50"
                  @change.prevent="changeReqPic($event)"
                >
                  <option value="">{{ $t('msg.ONEX070G110.015') }}</option><!-- 직접입력 -->
                  <option
                  v-for="(manager, idx) in managerList"
                  :key="manager.picNo"
                  :value="idx"
                  >
                    {{ manager.picNm }}
                  </option>
                </select>
                <input
                  id="re_pic_nm"
                  class="inp_space50 ml3"
                  v-model="params.reqPicNm"
                  type="text"
                  :readonly="readOnly === true"
                  @keyup="fnValidationPicNm()"
                >
              </td>
              <td>
                <e-input-number
                  v-model="params.reqPicTelNo"
                  id="re_pic_tel_no"
                  :readonly="readOnly"
                  :isPhone="true"
                  @input="fnValidationPicTelNo()"
                />
              </td>
              <td>
                <input
                  v-model="params.reqPicEmlAddr"
                  id="re_pic_eml_addr"
                  type="text"
                  :readonly="readOnly === true"
                  @keyup="fnValidationPicEmlAddr()"
                >
              </td>
            </tr>

          </tbody>
        </table>

        <h2 class="content_title">{{ $t('msg.ONEX070G110.016') }}</h2><!-- 사유 및 요청사항 -->
        <div>
          <textarea
            v-model="params.reqRsnCont"
            id="req_rsn_cont"
            @keyup="fnValidationRsnCont()"
          />
        </div>
        <p class="txt_desc">
          {{ $t('msg.ONEX070G110.017') }}
        </p><!-- 재 발행 요청은 C/A 최종 승인 후 가능하며 기존 O.B/L은 전통(Full set) 반납하여 주시기 바랍니다 -->
      </div><!-- content_box // -->
    </form>

    <div class="mt10 text_center">
      <a
        class="button blue lg"
        href="#"
        @click.prevent="OriginalBLReIssueInsert()"
      >{{ $t('msg.ONEX070G110.018') }}</a><!-- 발행요청 -->
      <a
        class="button gray lg"
        href="#"
        @click.prevent="$emit('close')"
      >{{ $t('msg.ONEX070G110.019') }}</a><!-- 취소 -->
    </div>

    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import docs from '@/api/rest/trans/docs'

export default {
  name: 'OriginalBLReIssuePop',
  components: {
    'e-input-number': () => import('@/components/common/EInputNumber')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          kind: '',
          blNo: '',
          reqCatCd: ''
        }
      }
    }
  },
  data () {
    return {
      params: {
        // [s] 요청자 연락처
        selPic: '',
        reqPicNo: '',
        reqPicNm: '',
        reqPicTelNo: '',
        reqPicEmlAddr: '',
        // 사유 및 요청사항
        reqRsnCont: '',
        // 요청 구분 코드
        reqCatCd: ''
      },
      // 스케줄 정보
      scheduleInfo: {},
      // 담당자 리스트
      managerList: [],
      readOnly: false
    }
  },
  created () {
    this.params.reqCatCd = this.$ekmtcCommon.isEmpty(this.parentInfo.reqCatCd) ? '08' : this.parentInfo.reqCatCd
    this.getOriginalBLReIssueInfo()
  },
  methods: {
    async getOriginalBLReIssueInfo () {
      await docs.getOriginalBLReIssueInfo(this.parentInfo)
        .then(response => {
          console.log(response)
          const resp = response.data

          if (resp.scheduleInfo !== undefined) {
            this.scheduleInfo = resp.scheduleInfo
          }
          if (resp.managerList !== undefined && resp.managerList.length > 0) {
            this.managerList = resp.managerList
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    //담당자 이름 선택시 이메일, 전화번호 자동 매핑
    changeReqPic (e) {
      const frm = document.querySelector('#frm_reissue')
      // this.$ekmtcCommon.hideErrorTooltipAll(frm)
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#re_pic_nm'))
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#re_pic_tel_no'))
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#re_pic_eml_addr'))
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#req_rsn_cont'))

      const idx = e.target.value
      let frmdata = this.params

      if (idx === '') {
        frmdata.selPic = ''
        frmdata.reqPicNo = ''
        frmdata.reqPicNm = ''
        frmdata.reqPicTelNo = ''
        frmdata.reqPicEmlAddr = ''

        this.readOnly = false
      } else {
        frmdata.selPic = idx
        frmdata.reqPicNo = this.$ekmtcCommon.isEmpty(this.managerList[idx].picNo) ? '' : this.managerList[idx].picNo
        frmdata.reqPicNm = this.$ekmtcCommon.isEmpty(this.managerList[idx].picNm) ? '' : this.managerList[idx].picNm
        frmdata.reqPicTelNo = (this.$ekmtcCommon.isEmpty(this.managerList[idx].telPlcNo) ? '' : this.managerList[idx].telPlcNo) + (this.$ekmtcCommon.isEmpty(this.managerList[idx].telOfcNo) ? '' : this.managerList[idx].telOfcNo) + (this.$ekmtcCommon.isEmpty(this.managerList[idx].telNo) ? '' : this.managerList[idx].telNo)
        frmdata.reqPicEmlAddr = this.$ekmtcCommon.isEmpty(this.managerList[idx].emlAddr) ? '' : this.managerList[idx].emlAddr

        this.readOnly = true
      }
    },
    checkValidationFrm (frm) {
      let isAllOk = true

      // '직접입력'일때 담당자 명, 연락처, email 체크
      if (this.params.selPic === '') {
        if (!this.fnValidationPicNm(frm)) {
          isAllOk = false
        }
        if (!this.fnValidationPicTelNo(frm)) {
          isAllOk = false
        }
        if (!this.fnValidationPicEmlAddr(frm)) {
          isAllOk = false
        }
      }

      if (!this.fnValidationRsnCont(frm)) {
        isAllOk = false
      }

      return isAllOk
    },
    fnValidationPicNm (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_reissue')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // '담당자 명' 체크
      elem = frm.querySelector('#re_pic_nm')
      if (this.$ekmtcCommon.isEmpty(this.params.reqPicNm)) {
        isOk = false
        msg = this.$t('msg.ONEX070G110.023') // 담당자 입력
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        if (!this.$ekmtcCommon.checkTextByte(this.params.reqPicNm, 100)) {
          isOk = false
          msg = this.$t('msg.ONEX070G110.026') // Byte 초과
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
      }

      return isOk
    },
    fnValidationPicTelNo (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_reissue')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // '연락처' 체크
      elem = frm.querySelector('#re_pic_tel_no')
      if (this.$ekmtcCommon.isEmpty(this.params.reqPicTelNo)) {
        isOk = false
        msg = this.$t('msg.ONEX070G110.024') // 연락처 입력
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        if (!this.$ekmtcCommon.checkTextByte(this.params.reqPicTelNo, 100)) {
          isOk = false
          msg = this.$t('msg.ONEX070G110.026') // Byte 초과
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
      }

      return isOk
    },
    fnValidationPicEmlAddr (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_reissue')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // 'email' 체크
      elem = frm.querySelector('#re_pic_eml_addr')
      if (this.$ekmtcCommon.isEmpty(this.params.reqPicEmlAddr)) {
        isOk = false
        msg = this.$t('msg.ONEX070G110.025') // E-Mail 입력
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)

        if (!this.$ekmtcCommon.checkEmail(this.params.reqPicEmlAddr)) {
          isOk = false
          msg = this.$t('msg.ONEX070G110.028') // E-Mail 형식 올바르지 않음.
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)

          if (!this.$ekmtcCommon.checkTextByte(this.params.reqPicEmlAddr, 100)) {
            isOk = false
            msg = this.$t('msg.ONEX070G110.026') // Byte 초과
            this.$ekmtcCommon.showErrorTooltip(elem, msg)
          } else {
            this.$ekmtcCommon.hideErrorTooltip(elem)
          }
        }
      }

      return isOk
    },
    fnValidationRsnCont (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm_reissue')
      }

      let isOk = true
      let elem = ''
      let msg = ''

      // '사유 및 요청사항' 체크
      elem = frm.querySelector('#req_rsn_cont')
      if (!this.$ekmtcCommon.checkTextByte(this.params.reqRsnCont, 2000)) {
        isOk = false
        msg = this.$t('msg.ONEX070G110.027') // 사유 및 요청사항 내용이 너무 많습니다.
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // O B/L 재발행 insert
    async OriginalBLReIssueInsert () {
      const frm = document.querySelector('#frm_reissue')

      const isOk = this.checkValidationFrm(frm)

      if (!isOk) {
        return
      }

      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX070G110.020'), useConfirmBtn: true })) {
        this.params = { ...this.params, ...this.scheduleInfo }

        await docs.reqDocSubmit(this.params)
          .then((response) => {
            console.log(response)
            if (response.headers.respcode === 'C0000') {
              const TH = this
              // 요청이 접수되었습니다.
              TH.$ekmtcCommon.alertCallback(TH.$t('msg.ONEX070G110.021'), () => {
                TH.$emit('close')
              })
            } else {
              // 요청 접수에 실패하였습니다.
              this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G110.022'))
            }
        })
        .catch(err => {
          console.log(err)
        })
      } else {
        return false
      }
    }
  }
}
</script>
