var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "900px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX070G110.001"))),
        ]),
        _c("form", { attrs: { id: "frm_reissue" } }, [
          _c("div", { staticClass: "content_box" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX070G110.002"))),
            ]),
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.003")))]),
                  _c("td", [_vm._v(_vm._s(_vm.scheduleInfo.bkgNo))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.004")))]),
                  _c("td", [_vm._v(_vm._s(_vm.scheduleInfo.blNo))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.005")))]),
                  _c("td", [_vm._v(_vm._s(_vm.scheduleInfo.polPlcNm))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.006")))]),
                  _c("td", [_vm._v(_vm._s(_vm.scheduleInfo.podPlcNm))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.007")))]),
                  _c("td", [_vm._v(_vm._s(_vm.scheduleInfo.vslNm))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.008")))]),
                  _c("td", [_vm._v(_vm._s(_vm.scheduleInfo.voyNo))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.009")))]),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _vm._v(_vm._s(_vm.scheduleInfo.actShprCstEnm)),
                  ]),
                ]),
              ]),
            ]),
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX070G110.010"))),
            ]),
            _c("table", { staticClass: "tbl_col" }, [
              _vm._m(1),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.011")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.012")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.013")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.014")))]),
                ]),
              ]),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(_vm.scheduleInfo.shprCstNm))]),
                  _c("td", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.selPic,
                            expression: "params.selPic",
                          },
                        ],
                        staticClass: "inp_space50",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.params,
                                "selPic",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              $event.preventDefault()
                              return _vm.changeReqPic($event)
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G110.015"))),
                        ]),
                        _vm._l(_vm.managerList, function (manager, idx) {
                          return _c(
                            "option",
                            { key: manager.picNo, domProps: { value: idx } },
                            [_vm._v(" " + _vm._s(manager.picNm) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.reqPicNm,
                          expression: "params.reqPicNm",
                        },
                      ],
                      staticClass: "inp_space50 ml3",
                      attrs: {
                        id: "re_pic_nm",
                        type: "text",
                        readonly: _vm.readOnly === true,
                      },
                      domProps: { value: _vm.params.reqPicNm },
                      on: {
                        keyup: function ($event) {
                          return _vm.fnValidationPicNm()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "reqPicNm", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c(
                    "td",
                    [
                      _c("e-input-number", {
                        attrs: {
                          id: "re_pic_tel_no",
                          readonly: _vm.readOnly,
                          isPhone: true,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.fnValidationPicTelNo()
                          },
                        },
                        model: {
                          value: _vm.params.reqPicTelNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "reqPicTelNo", $$v)
                          },
                          expression: "params.reqPicTelNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.reqPicEmlAddr,
                          expression: "params.reqPicEmlAddr",
                        },
                      ],
                      attrs: {
                        id: "re_pic_eml_addr",
                        type: "text",
                        readonly: _vm.readOnly === true,
                      },
                      domProps: { value: _vm.params.reqPicEmlAddr },
                      on: {
                        keyup: function ($event) {
                          return _vm.fnValidationPicEmlAddr()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.params,
                            "reqPicEmlAddr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX070G110.016"))),
            ]),
            _c("div", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.params.reqRsnCont,
                    expression: "params.reqRsnCont",
                  },
                ],
                attrs: { id: "req_rsn_cont" },
                domProps: { value: _vm.params.reqRsnCont },
                on: {
                  keyup: function ($event) {
                    return _vm.fnValidationRsnCont()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.params, "reqRsnCont", $event.target.value)
                  },
                },
              }),
            ]),
            _c("p", { staticClass: "txt_desc" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.ONEX070G110.017")) + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.OriginalBLReIssueInsert()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.018")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX070G110.019")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "26%" } }),
      _c("col", { attrs: { width: "18%" } }),
      _c("col", { attrs: { width: "26%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }